import React from 'react';
import classNames from 'classnames';
import styles from './Th.module.css';

type Props = {
  className?: string;
} & React.ThHTMLAttributes<any>;

export const Th: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <th className={classNames(styles.th, className)} {...props}>
      {children}
    </th>
  );
};
