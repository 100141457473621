import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RecentPosts } from './recentPosts/RecentPosts';
import { selectUser, userDetailActions } from './userDetailSlice';
import { LeadText } from '../../../components/typography/LeadText';
import styles from './UserDetail.module.css';
import { RecentReactions } from './recentReactions/RecentReactions';
import dayjs from 'dayjs';

type Params = {
  userId: string;
};

export const UserDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { userId } = useParams<Params>();

  useEffect(() => {
    dispatch(userDetailActions.fetchUser(userId));
  }, [dispatch, userId]);

  if (!user) {
    return <div>ユーザ情報取得中...</div>;
  }

  return (
    <div>
      <div className={styles.pageMenu}>
        <LeadText>{user.name}</LeadText>
        <p>
          <small>ID: {user.id}</small>
        </p>
        <p>
          <small>
            登録日: {dayjs(user.createdAt).format('YYYY年MM月DD日 HH時mm分')}
          </small>
        </p>
      </div>
      <div className="mt-6">
        <RecentPosts userId={userId} />
      </div>
      <div className="mt-6">
        <RecentReactions userId={userId} />
      </div>
    </div>
  );
};
