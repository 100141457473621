import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  name: string;
  order: string;
  orderBy: string;
};

export const SortIcon: React.FC<Props> = ({ name, order, orderBy }) => {
  let icon = faSort;
  if (name === order && orderBy === 'ASC') {
    icon = faSortUp;
  } else if (name === order && orderBy === 'DESC') {
    icon = faSortDown;
  }
  return <FontAwesomeIcon icon={icon} />;
};
