import React from 'react';
import classNames from 'classnames';
import styles from './Td.module.css';

type Props = {
  className?: string;
} & React.TdHTMLAttributes<any>;

export const Td: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <td className={classNames(styles.td, className)} {...props}>
      {children}
    </td>
  );
};
