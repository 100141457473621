import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  noroshiSuggestionListActions,
  selectChecks,
  selectCount,
  selectSuggestions,
  selectPage,
  selectSearch,
  selectTotalCount,
} from './noroshiSuggestionListSlice';
import { SearchInput } from '../../components/form/SearchInput';
import { DropdownButton } from '../../components/button/DropdownButton';
import { Table } from '../../components/table/Table';
import { Thead } from '../../components/table/Thead';
import { Tbody } from '../../components/table/Tbody';
import { Th } from '../../components/table/Th';
import { Td } from '../../components/table/Td';
import { Pagination } from '../../components/navigation/Pagination';
import styles from './NoroshiSuggestionList.module.css';
import { Link } from 'react-router-dom';

export const NoroshiSuggestionList: React.FC = () => {
  const dispatch = useAppDispatch();
  const suggestions = useAppSelector(selectSuggestions);
  const totalCount = useAppSelector(selectTotalCount);
  const page = useAppSelector(selectPage);
  const count = useAppSelector(selectCount);
  const search = useAppSelector(selectSearch);
  const checks = useAppSelector(selectChecks);

  useEffect(() => {
    dispatch(noroshiSuggestionListActions.fetchNoroshiSuggestions());
  }, [dispatch, search, page]);

  const changeSearch = useCallback(
    (message: string) => {
      dispatch(noroshiSuggestionListActions.changeSearch(message));
    },
    [dispatch],
  );

  const changePage = useCallback(
    (page: number) => {
      dispatch(noroshiSuggestionListActions.changePage(page));
    },
    [dispatch],
  );

  const checkAll = useCallback(() => {
    dispatch(noroshiSuggestionListActions.checkAll());
  }, [dispatch]);

  const check = useCallback(
    (id: number) => {
      return () => {
        dispatch(noroshiSuggestionListActions.check(id));
      };
    },
    [dispatch],
  );

  return (
    <div>
      <div className={styles.pageMenu}>
        <div className={classNames(styles.pageMenu, 'flex-1')}>
          {checks.length > 0 && (
            <DropdownButton
              label="アクション"
              items={[
                {
                  label: '採用',
                  onClick: () => {},
                },
                {
                  label: '不採用',
                  onClick: () => {},
                },
              ]}
            />
          )}
        </div>
        <div className={styles.pageMenu}>
          <SearchInput
            placeholder="提案されたメッセージで検索"
            handleChange={changeSearch}
          />
          <Pagination
            totalCount={totalCount}
            page={page}
            count={count}
            onPageChange={changePage}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table>
          <Thead>
            <tr>
              <Th>
                <input
                  type="checkbox"
                  checked={
                    checks.length > 0 &&
                    suggestions.every((suggestion) =>
                      checks.includes(suggestion.id),
                    )
                  }
                  onChange={checkAll}
                />
              </Th>
              <Th>ID</Th>
              <Th>提案時間</Th>
              <Th>ユーザID</Th>
              <Th>ユーザ名</Th>
              <Th>提案されたメッセージ</Th>
              <Th>ステータス</Th>
              <Th>登録日時</Th>
              <Th>更新日時</Th>
            </tr>
          </Thead>
          <Tbody>
            {suggestions.map((suggestion) => (
              <tr key={suggestion.id}>
                <Td>
                  <input
                    type="checkbox"
                    checked={checks.includes(suggestion.id)}
                    onChange={check(suggestion.id)}
                  />
                </Td>
                <Td>{suggestion.id}</Td>
                <Td>
                  {dayjs(suggestion.time).format('YYYY年MM月DD日 HH時mm分')}
                </Td>
                <Td>{suggestion.userId}</Td>
                <Td>
                  <Link
                    className={styles.textLink}
                    to={`/users/${suggestion.userId}`}
                  >
                    {suggestion.user.name}
                  </Link>
                </Td>
                <Td>{suggestion.message}</Td>
                <Td>{suggestion.status}</Td>
                <Td>
                  {dayjs(suggestion.createdAt).format(
                    'YYYY年MM月DD日 HH時mm分',
                  )}
                </Td>
                <Td>
                  {dayjs(suggestion.updatedAt).format(
                    'YYYY年MM月DD日 HH時mm分',
                  )}
                </Td>
              </tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};
