import firebase from '.';
import { gapi } from '../gapi';

// Googleユーザがfirebaseユーザとしてログイン済みかどうかを判定する
const isUserEqual = (
  googleUser: gapi.auth2.GoogleUser,
  firebaseUser: firebase.User | null,
): boolean => {
  if (firebaseUser) {
    return firebaseUser.providerData.some(
      (providerData) =>
        providerData?.providerId ===
          firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
        providerData?.uid === googleUser.getBasicProfile().getId(),
    );
  }
  return false;
};

// ログイン処理
// Googleでログイン後にその認証情報を使ってfirebaseにログインする
export const login = async (): Promise<void> => {
  const auth2 = gapi.auth2.getAuthInstance();
  const isSignedIn = auth2.isSignedIn.get();
  // sign in gapi.
  const googleUser = isSignedIn
    ? auth2.currentUser.get()
    : await auth2.signIn();
  // check firebase sign in state.
  const firebaseUser = firebase.auth().currentUser;
  if (isUserEqual(googleUser, firebaseUser)) {
    return;
  }
  // sign in firebase.
  const authResponse = googleUser.getAuthResponse(true);
  const credential = firebase.auth.GoogleAuthProvider.credential(
    authResponse.id_token,
    authResponse.access_token,
  );
  await firebase.auth().signInWithCredential(credential);
};

// ログアウト処理
// Googleとfirebaseの両方からログアウトする
export const logout = async (): Promise<void> => {
  const auth2 = gapi.auth2.getAuthInstance();
  await auth2.signOut();
  await firebase.auth().signOut();
};
