import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import { endpoint } from '../../services/bff';

export const createNoroshiMasterBulk = async (
  messages: string[],
  idToken: string,
): Promise<Partial<NoroshiMaster>> => {
  const res = await fetch(`${endpoint()}/v1/noroshi-master/bulk`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ messages }),
  });
  return res.json();
};
