import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { authActions } from './authSlice';
import { Login } from './Login';
import { PrivateRoute } from './PrivateRoute';

export const AuthGuard: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authActions.checkAuth());
  }, [dispatch]);

  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>
      <PrivateRoute path="/">{children}</PrivateRoute>
    </Switch>
  );
};
