import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import counterReducer from '../features/counter/counterSlice';
import noroshiMasterListReducer from '../features/noroshiMaster/noroshiMasterListSlice';
import noroshiMasterCreateReducer from '../features/noroshiMasterCreate/noroshiMasterCreateSlice';
import noroshiMasterEditReducer from '../features/noroshiMasterEdit/noroshiMasterEditSlice';
import noroshiSuggestionListReducer from '../features/noroshiSuggestion/noroshiSuggestionListSlice';
import recentPostsReducer from '../features/dashboard/recentPosts/recentPostsSlice';
import userListReducer from '../features/users/userListSlice';
import userDetailReducer from '../features/users/detail/userDetailSlice';
import usersRecentPostsReducer from '../features/users/detail/recentPosts/recentPostsSlice';
import usersRecentReactionsReducer from '../features/users/detail/recentReactions/recentReactionsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    counter: counterReducer,
    noroshiMasterList: noroshiMasterListReducer,
    noroshiMasterCreate: noroshiMasterCreateReducer,
    noroshiMasterEdit: noroshiMasterEditReducer,
    noroshiSuggestionList: noroshiSuggestionListReducer,
    recentPosts: recentPostsReducer,
    userList: userListReducer,
    userDetail: userDetailReducer,
    usersRecentPosts: usersRecentPostsReducer,
    usersRecentReactions: usersRecentReactionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
