import { User } from '../../models/dtos/User';
import { Pagination } from '../../models/dtos/Pagination';
import { endpoint } from '../../services/bff';

export const fetchUsers = async (
  name: string,
  page: number,
  count: number,
  order: string,
  orderBy: string,
  idToken: string,
): Promise<Pagination<User>> => {
  const res = await fetch(
    `${endpoint()}/v1/users?name=${name}&page=${page}&count=${count}&order=${order}&orderBy=${orderBy}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return res.json();
};

export const deleteUsers = async (
  id: Array<string>,
  idToken: string,
): Promise<void> => {
  await fetch(
    `${endpoint()}/v1/users/deletes`,
    {
      method: 'POST',
      body:    JSON.stringify(id),
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
}
