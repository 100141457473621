import React, { useCallback } from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { NoroshiMasterCreateForm } from './NoroshiMasterCreateForm';
import {
  noroshiMasterCreateActions,
  selectIsOpenModal,
} from './noroshiMasterCreateSlice';
import styles from './NoroshiMasterCreate.module.css';

Modal.setAppElement('#root');

export const NoroshiMasterCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpenModal = useAppSelector(selectIsOpenModal);

  const createNoroshiMasterBulk = useCallback(
    (masters: string[]) => {
      dispatch(noroshiMasterCreateActions.createNoroshiMasterBulk(masters));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch(noroshiMasterCreateActions.closeModal());
  }, [dispatch]);

  return (
    <Modal
      className={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={isOpenModal}
      onRequestClose={closeModal}
    >
      <NoroshiMasterCreateForm
        onSubmit={createNoroshiMasterBulk}
        onClose={closeModal}
      />
    </Modal>
  );
};
