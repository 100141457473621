import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecentReaction } from '../../../../models/dtos/RecentReaction';
import { AppDispatch, RootState } from '../../../../app/store';
import { fetchRecentReactions } from './recentReactionsAPI';
import { Pagination } from '../../../../models/dtos/Pagination';

export interface RecentReactionsState {
  posts: RecentReaction[];
  totalCount: number;
  page: number;
  count: number;
}

const initialState: RecentReactionsState = {
  posts: [],
  totalCount: 0,
  page: 1,
  count: 20,
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const fetchRecentReactionsAsync = createAsyncThunk<
  Pagination<RecentReaction>,
  string,
  AsyncThunkConfig
>('users/detail/recentReactions/fetch', async (userId, { getState }) => {
  const state = getState();
  const { page, count } = state.usersRecentReactions;
  if (state.auth.currentUserToken) {
    return fetchRecentReactions(
      userId,
      page,
      count,
      state.auth.currentUserToken,
    );
  } else {
    throw new Error('Authorization Error.');
  }
});

export const recentReactionsSlice = createSlice({
  name: 'users/detail/recentReactions',
  initialState,
  reducers: {
    changePage: (
      state: RecentReactionsState,
      action: PayloadAction<number>,
    ) => {
      state.page = action.payload;
    },
    resetPage: (state: RecentReactionsState) => {
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRecentReactionsAsync.fulfilled,
      (
        state: RecentReactionsState,
        action: PayloadAction<Pagination<RecentReaction>>,
      ) => {
        state.posts = action.payload.results;
        state.totalCount = action.payload.totalCount;
      },
    );
  },
});

export const recentReactionsActions = {
  ...recentReactionsSlice.actions,
  fetchRecentReactions: fetchRecentReactionsAsync,
};

export const selectRecentReactions = (state: RootState) =>
  state.usersRecentReactions.posts;
export const selectTotalCount = (state: RootState) =>
  state.usersRecentReactions.totalCount;
export const selectPage = (state: RootState) => state.usersRecentReactions.page;
export const selectCount = (state: RootState) =>
  state.usersRecentReactions.count;

export default recentReactionsSlice.reducer;
