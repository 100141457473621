import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.css';

type Props = {
  outlined?: boolean;
} & React.ButtonHTMLAttributes<any>;

export const Button: React.FC<Props> = ({ children, outlined, ...props }) => {
  const className = classNames({
    [styles.button]: !outlined,
    [styles.outlined]: outlined,
  });
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};
