import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { AuthGuard } from './features/auth/AuthGuard';
import { LogoutButton } from './features/auth/LogoutButton';
import { RecentPosts } from './features/dashboard/recentPosts/RecentPosts';
import { UserList } from './features/users/UserList';
import { UserDetail } from './features/users/detail/UserDetail';
import { NoroshiMasterList } from './features/noroshiMaster/NoroshiMasterList';
import { NoroshiSuggestionList } from './features/noroshiSuggestion/NoroshiSuggestionList';
import logo from '../public/Icon-norosy_500_icon.png';
import { selectIsLoggedIn } from './features/auth/authSlice';
import { useAppSelector } from './app/hooks';
import styles from './App.module.css';

const App: React.FC = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  return (
    <Router>
      <header>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
            <Link to="/">norosy admin</Link>
          </div>
          <div className={styles.menu}>
            <Link to="/">Dashboard</Link>
            <Link to="/users">User</Link>
            <Link to="/noroshiMaster">Master</Link>
            <Link to="/noroshiSuggestion">Suggestion</Link>
          </div>
          <div className={styles.logout}>{isLoggedIn && <LogoutButton />}</div>
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.container}>
          <AuthGuard>
            <Switch>
              <Route path="/" component={RecentPosts} exact />
              <Route path="/users" component={UserList} exact />
              <Route path="/users/:userId" component={UserDetail} exact />
              <Route
                path="/noroshiMaster"
                component={NoroshiMasterList}
                exact
              />
              <Route
                path="/noroshiSuggestion"
                component={NoroshiSuggestionList}
                exact
              />
            </Switch>
          </AuthGuard>
        </div>
      </main>
    </Router>
  );
};

export default App;
