import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

if (!firebase.apps.length) {
  // Initialize Cloud Firestore through Firebase
  firebase.initializeApp({
    apiKey: 'AIzaSyAOWT-YoItOkU86twQmfTW1jQO_W35eZEU',
    authDomain: 'norosy.firebaseapp.com',
    projectId: 'norosy',
    storageBucket: 'norosy.appspot.com',
    messagingSenderId: '209022559734',
    appId: '1:209022559734:web:9370c3e5a0c52b7c9fd3d7',
    measurementId: 'G-3WENRJHSFJ',
  });
}

// firestore instance
export const db = firebase.firestore();

// firestoreは開発版でemulatorを使用したいが動きが怪しい
// (FirebaseError: false for 'list' @ L6)
// if (window.location.hostname === 'localhost') {
//   db.settings({
//     host: 'localhost:8080',
//     ssl: false
//   });
// }

export default firebase;
