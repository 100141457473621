import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  userListActions,
  selectChecks,
  selectCount,
  selectUsers,
  selectPage,
  selectSearch,
  selectTotalCount,
  selectOrder,
  selectOrderBy,
} from './userListSlice';
import { SearchInput } from '../../components/form/SearchInput';
import { DropdownButton } from '../../components/button/DropdownButton';
import { Table } from '../../components/table/Table';
import { Thead } from '../../components/table/Thead';
import { Tbody } from '../../components/table/Tbody';
import { Th } from '../../components/table/Th';
import { Td } from '../../components/table/Td';
import { Pagination } from '../../components/navigation/Pagination';
import styles from './UserList.module.css';
import { SortIcon } from '../../components/table/SortIcon';
import { Link } from 'react-router-dom';

export const UserList: React.FC = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const totalCount = useAppSelector(selectTotalCount);
  const page = useAppSelector(selectPage);
  const count = useAppSelector(selectCount);
  const order = useAppSelector(selectOrder);
  const orderBy = useAppSelector(selectOrderBy);
  const search = useAppSelector(selectSearch);
  const checks = useAppSelector(selectChecks);

  useEffect(() => {
    dispatch(userListActions.fetchUsers());
  }, [dispatch, search, page, order, orderBy]);

  const changeSearch = useCallback(
    (name: string) => {
      dispatch(userListActions.changeSearch(name));
    },
    [dispatch],
  );

  const changePage = useCallback(
    (page: number) => {
      dispatch(userListActions.changePage(page));
    },
    [dispatch],
  );

  const changeOrder = useCallback(
    (name: string) => {
      return () => {
        dispatch(userListActions.changeOrder(name));
      };
    },
    [dispatch],
  );

  const checkAll = useCallback(() => {
    dispatch(userListActions.checkAll());
  }, [dispatch]);

  const check = useCallback(
    (id: string) => {
      return () => {
        dispatch(userListActions.check(id));
      };
    },
    [dispatch],
  );

  const deleteUsers = useCallback(() => {
    dispatch(userListActions.deleteUsers());
    dispatch(userListActions.checkClear());
    dispatch(userListActions.fetchUsers());
  }, [dispatch]);

  return (
    <div>
      <div className={styles.pageMenu}>
        <div className={classNames(styles.pageMenu, 'flex-1')}>
          {checks.length > 0 && (
            <DropdownButton
              label="アクション"
              items={[
                {
                  label: '削除',
                  onClick: () => {
                    deleteUsers();
                  },
                },
              ]}
            />
          )}
        </div>
        <div className={styles.pageMenu}>
          <SearchInput
            placeholder="ユーザ名で検索"
            handleChange={changeSearch}
          />
          <Pagination
            totalCount={totalCount}
            page={page}
            count={count}
            onPageChange={changePage}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table>
          <Thead>
            <tr>
              <Th>
                <input
                  type="checkbox"
                  checked={
                    checks.length > 0 &&
                    users.every((user) => checks.includes(user.id))
                  }
                  onChange={checkAll}
                />
              </Th>
              <Th className="cursor-pointer" onClick={changeOrder('id')}>
                <span className="mr-2">ID</span>
                <SortIcon name="id" order={order} orderBy={orderBy} />
              </Th>
              <Th className="cursor-pointer" onClick={changeOrder('name')}>
                <span className="mr-2">名前</span>
                <SortIcon name="name" order={order} orderBy={orderBy} />
              </Th>
              <Th className="cursor-pointer" onClick={changeOrder('createdAt')}>
                <span className="mr-2">登録日時</span>
                <SortIcon name="createdAt" order={order} orderBy={orderBy} />
              </Th>
              <Th className="cursor-pointer" onClick={changeOrder('updatedAt')}>
                <span className="mr-2">更新日時</span>
                <SortIcon name="updatedAt" order={order} orderBy={orderBy} />
              </Th>
              <Th className="cursor-pointer" onClick={changeOrder('deleteAt')}>
                <span className="mr-2">削除日時</span>
                <SortIcon name="deletedAt" order={order} orderBy={orderBy} />
              </Th>
            </tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <Td>
                  <input
                    type="checkbox"
                    checked={checks.includes(user.id)}
                    onChange={check(user.id)}
                  />
                </Td>
                <Td>{user.id}</Td>
                <Td>
                  <Link className={styles.textLink} to={`/users/${user.id}`}>
                    {user.name}
                  </Link>
                </Td>
                <Td>
                  {dayjs(user.createdAt).format('YYYY年MM月DD日 HH時mm分')}
                </Td>
                <Td>
                  {dayjs(user.updatedAt).format('YYYY年MM月DD日 HH時mm分')}
                </Td>
                <Td>
                  {!user.deletedAt
                    ? ''
                    : dayjs(user.deletedAt).format('YYYY年MM月DD日 HH時mm分')}
                </Td>
              </tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};
