import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import styles from './Pagination.module.css';

type Props = {
  totalCount: number;
  page: number;
  count: number;
  onPageChange(page: number): void;
};

export const Pagination: React.FC<Props> = ({
  totalCount,
  page,
  count,
  onPageChange,
}) => {
  const totalPage = useMemo(() => {
    return Math.ceil(totalCount / count);
  }, [totalCount, count]);

  return (
    <ReactPaginate
      containerClassName={styles.container}
      pageClassName={styles.page}
      activeClassName={styles.active}
      previousClassName={styles.previous}
      nextClassName={styles.next}
      forcePage={page - 1}
      pageCount={totalPage}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
      nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
    />
  );
};
