import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../app/store';
import { fetchUser } from './userDetailAPI';
import { User } from '../../../models/dtos/User';

export interface UserDetailState {
  user?: User;
}

const initialState: UserDetailState = {
  user: undefined,
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const fetchUserAsync = createAsyncThunk<User, string, AsyncThunkConfig>(
  'users/detail/fetch',
  async (userId, { getState }) => {
    const state = getState();
    if (state.auth.currentUserToken) {
      return fetchUser(userId, state.auth.currentUserToken);
    } else {
      throw new Error('Authorization Error.');
    }
  },
);

export const userDetailSlice = createSlice({
  name: 'users/detail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchUserAsync.fulfilled,
      (state: UserDetailState, action: PayloadAction<User>) => {
        state.user = action.payload;
      },
    );
  },
});

export const userDetailActions = {
  ...userDetailSlice.actions,
  fetchUser: fetchUserAsync,
};

export const selectUser = (state: RootState) => state.userDetail.user;

export default userDetailSlice.reducer;
