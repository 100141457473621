import React from 'react';
import { useLocation, Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser, selectIsFirstLoaded } from './authSlice';
import styles from './PrivateRoute.module.css';

// 認証が必要なRoute
export const PrivateRoute: React.FC<RouteProps> = ({ ...props }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const isFirstLoaded = useAppSelector(selectIsFirstLoaded);
  const location = useLocation();
  const prev = encodeURIComponent(`${location.pathname}${location.search}`);

  // FIXME: Loading View
  if (!isFirstLoaded) {
    return <div className={styles.container}>認証情報取得中...</div>;
  }

  return currentUser ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login', search: `?prev=${prev}` }} />
  );
};
