import firebase from 'firebase';
import { AdminUser } from '../../models/dtos/AdminUser';
import { collections } from '../../services/firebase/collections';
import { plainToClass } from 'class-transformer';

export const findUser = async (id: string): Promise<AdminUser | undefined> => {
  const snapshot = await collections.users().doc(id).get();
  const data = snapshot.data();
  if (snapshot.exists && data) {
    return plainToClass(AdminUser, data);
  }
};

export const createUser = async (
  firebaseUser: firebase.User,
): Promise<AdminUser> => {
  const user = {
    id: firebaseUser.uid,
    providerId: firebaseUser.providerId,
    uid: firebaseUser.uid,
    displayName: firebaseUser.displayName,
    email: firebaseUser.email,
    photoURL: firebaseUser.photoURL,
    isAdmin: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  await collections.users().doc(user.id).set(user);
  return plainToClass(AdminUser, user);
};
