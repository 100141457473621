import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { User } from '../../models/dtos/User';
import { Pagination } from '../../models/dtos/Pagination';
import { fetchUsers, deleteUsers } from './userListAPI';

export interface UserListState {
  users: User[];
  totalCount: number;
  page: number;
  count: number;
  order: string;
  orderBy: string;
  search: string;
  checks: string[];
}

const initialState: UserListState = {
  users: [],
  totalCount: 0,
  page: 1,
  count: 100,
  order: 'createdAt',
  orderBy: 'DESC',
  search: '',
  checks: [],
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const fetchUsersAsync = createAsyncThunk<
  Pagination<User>,
  undefined,
  AsyncThunkConfig
>('userList/fetch', async (_, { getState }) => {
  const state = getState();
  const { search, page, count, order, orderBy } = state.userList;
  if (state.auth.currentUserToken) {
    return fetchUsers(
      search,
      page,
      count,
      order,
      orderBy,
      state.auth.currentUserToken,
    );
  } else {
    throw new Error('Authorization Error.');
  }
});

const deleteUsersAsync = createAsyncThunk<
  void,
  undefined,
  AsyncThunkConfig>('userList/delete', async (_, {getState}) => {
  const state = getState();
  const { checks } = state.userList;
  if (state.auth.currentUserToken) {
    deleteUsers(
      checks,
      state.auth.currentUserToken
    );
  } else {
    throw new Error('Authorization Error.');
  }
});

export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    changeSearch: (state: UserListState, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    changePage: (state: UserListState, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetPage: (state: UserListState) => {
      state.page = 1;
    },
    changeOrder: (state: UserListState, action: PayloadAction<string>) => {
      if (state.order !== action.payload) {
        state.order = action.payload;
        state.orderBy = 'DESC';
      } else {
        state.order = action.payload;
        state.orderBy = state.orderBy === 'DESC' ? 'ASC' : 'DESC';
      }
    },
    checkAll: (state: UserListState, _: PayloadAction<void>) => {
      if (state.users.every((user) => state.checks.includes(user.id))) {
        state.checks = [];
      } else {
        state.checks = state.users.map((master) => master.id);
      }
    },
    check: (state: UserListState, action: PayloadAction<string>) => {
      if (state.checks.includes(action.payload)) {
        state.checks = state.checks.filter((id) => id !== action.payload);
      } else {
        state.checks.push(action.payload);
      }
    },
    checkClear:(state: UserListState, _: PayloadAction<void>) => {
      state.checks = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUsersAsync.fulfilled,
      (state: UserListState, action: PayloadAction<Pagination<User>>) => {
        state.users = action.payload.results;
        state.totalCount = action.payload.totalCount;
      },
    );
  },
});

export const userListActions = {
  ...userListSlice.actions,
  fetchUsers: fetchUsersAsync,
  deleteUsers: deleteUsersAsync,
};

export const selectUsers = (state: RootState) => state.userList.users;
export const selectTotalCount = (state: RootState) => state.userList.totalCount;
export const selectPage = (state: RootState) => state.userList.page;
export const selectCount = (state: RootState) => state.userList.count;
export const selectOrder = (state: RootState) => state.userList.order;
export const selectOrderBy = (state: RootState) => state.userList.orderBy;
export const selectSearch = (state: RootState) => state.userList.search;
export const selectChecks = (state: RootState) => state.userList.checks;

export default userListSlice.reducer;
