import React from 'react';
import styles from './Table.module.css';

export const Table: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper1}>
        <div className={styles.wrapper2}>
          <div className={styles.wrapper3}>
            <table>{children}</table>
          </div>
        </div>
      </div>
    </div>
  );
};
