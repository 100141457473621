import { classToPlain } from 'class-transformer';
import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import { endpoint } from '../../services/bff';

export const updateNoroshiMaster = async (
  noroshiMaster: Partial<NoroshiMaster>,
  idToken: string,
): Promise<Partial<NoroshiMaster>> => {
  const res = await fetch(
    `${endpoint()}/v1/noroshi-master/${noroshiMaster.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(classToPlain(noroshiMaster)),
    },
  );
  return res.json();
};
