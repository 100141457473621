import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import { Pagination } from '../../models/dtos/Pagination';
import { endpoint } from '../../services/bff';

export const fetchNoroshiMasters = async (
  message: string,
  page: number,
  count: number,
  idToken: string,
): Promise<Pagination<NoroshiMaster>> => {
  const res = await fetch(
    `${endpoint()}/v1/noroshi-master?message=${message}&page=${page}&count=${count}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return res.json();
};

export const deleteNoroshiMasters = async (
  ids: number[],
  idToken: string,
): Promise<void> => {
  await fetch(`${endpoint()}/v1/noroshi-master/bulk`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ids }),
  });
};
