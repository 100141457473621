import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { LocationDescriptor } from 'history';
import queryString from 'query-string';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from './authSlice';
import { LoginButton } from './LoginButton';
import styles from './Login.module.css';

export const Login: React.FC = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const location = useLocation<{ from: LocationDescriptor }>();
  const qs = queryString.parse(location.search);
  const replace = qs.prev ? decodeURIComponent(qs.prev as string) : '/';

  if (currentUser) {
    return <Redirect to={replace} />;
  }

  return (
    <div className={styles.container}>
      <LoginButton />
    </div>
  );
};
