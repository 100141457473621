export class AdminUser {
  id!: string;
  uid!: string;
  providerId!: string;
  displayName!: string;
  email!: string;
  photoURL!: string;
  isAdmin!: boolean;
  createdAt!: Date;
  updatedAt!: Date;
}
