import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './DropdownButton.module.css';

type DropdownMenuItems = {
  className?: string;
  icon?: IconDefinition;
  label: string;
  onClick(): void;
};

type Props = {
  label: string;
  items: DropdownMenuItems[];
};

export const DropdownButton: React.FC<Props> = ({ label, items }) => {
  return (
    <div className="relative">
      <Menu>
        <Menu.Button className={styles.button}>{label}</Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={styles.menuItems}>
            <div className="px-1 py-1">
              {items.map((item) => (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <button
                      className={classNames(styles.menuItem, item.className, {
                        'text-gray-900': !active,
                        'bg-gray-100': active,
                      })}
                      onClick={item.onClick}
                    >
                      {item.icon && (
                        <FontAwesomeIcon className="mr-3" icon={item.icon} />
                      )}
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
