import React, { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import {
  recentPostsActions,
  selectCount,
  selectPage,
  selectRecentPosts,
  selectTotalCount,
} from './recentPostsSlice';
import { Table } from '../../../../components/table/Table';
import { Thead } from '../../../../components/table/Thead';
import { Tbody } from '../../../../components/table/Tbody';
import { Th } from '../../../../components/table/Th';
import { Td } from '../../../../components/table/Td';
import { LeadText } from '../../../../components/typography/LeadText';
import { Pagination } from '../../../../components/navigation/Pagination';
import styles from './RecentPosts.module.css';
import { Link } from 'react-router-dom';

type Props = {
  userId: string;
};

export const RecentPosts: React.FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const recentPosts = useAppSelector(selectRecentPosts);
  const totalCount = useAppSelector(selectTotalCount);
  const page = useAppSelector(selectPage);
  const count = useAppSelector(selectCount);

  useEffect(() => {
    dispatch(recentPostsActions.fetchRecentPosts(userId));
  }, [dispatch, page, userId]);

  const changePage = useCallback(
    (page: number) => {
      dispatch(recentPostsActions.changePage(page));
    },
    [dispatch],
  );

  return (
    <div>
      <div className={styles.pageMenu}>
        <div className={classNames(styles.pageMenu, 'flex-1')}>
          <LeadText>直近の狼煙投稿 ({totalCount})</LeadText>
        </div>
        <div className={styles.pageMenu}>
          <Pagination
            totalCount={totalCount}
            page={page}
            count={count}
            onPageChange={changePage}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table>
          <Thead>
            <tr>
              <Th>ID</Th>
              <Th>投稿時間</Th>
              <Th>ユーザ名</Th>
              <Th>メッセージ</Th>
              <Th>リアクション数</Th>
            </tr>
          </Thead>
          <Tbody>
            {recentPosts.map((post) => (
              <tr>
                <Td>{post.id}</Td>
                <Td>
                  {dayjs(post.createdAt).format('YYYY年MM月DD日 HH時mm分')}
                </Td>
                <Td>
                  <Link
                    className={styles.textLink}
                    to={`/users/${post.userId}`}
                  >
                    {post.userName}
                  </Link>
                </Td>
                <Td>{post.message}</Td>
                <Td>{post.reactions}</Td>
              </tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};
