import React, { ChangeEvent, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './SearchInput.module.css';

type Props = {
  placeholder?: string;
  handleChange?(text: string): void;
};

export const SearchInput: React.FC<Props> = ({ placeholder = '検索', handleChange }) => {
  const [value, setValue] = useState('');
  const [delayTimer, setDelayTimer] = useState<NodeJS.Timer | undefined>(undefined);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setValue(value);
      if (handleChange) {
        if (delayTimer) {
          clearTimeout(delayTimer);
          setDelayTimer(undefined);
        }
        setDelayTimer(
          setTimeout(() => {
            handleChange(value);
            setDelayTimer(undefined);
          }, 1000),
        );
      }
    },
    [handleChange, delayTimer],
  );

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
      />
      <span className={styles.icon}>
        <FontAwesomeIcon icon={faSearch} />
      </span>
    </div>
  );
};
