import React from 'react';
import { withFormik, FormikProps, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import { Button } from '../../components/button/Button';
import styles from './NoroshiMasterEditForm.module.css';

type Props = {
  noroshiMaster?: Partial<NoroshiMaster>;
  onSubmit(noroshiMaster: Partial<NoroshiMaster>): void;
  onClose(): void;
};

const Form: React.FC<Props & FormikProps<Partial<NoroshiMaster>>> = ({
  handleSubmit,
  onClose,
}) => {
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <p className={styles.formHeader}>noroshiマスタ更新</p>
      <div className={styles.formBody}>
        <label htmlFor="message" className={styles.inputLabel}>
          メッセージ
        </label>
        <Field
          id="message"
          name="message"
          className={styles.input}
          placeholder="メッセージ"
        />
        <div className={styles.inputError}>
          <ErrorMessage name="message" />
        </div>
      </div>
      <div className={styles.formFooter}>
        <Button type="submit">更新</Button>
        <Button type="button" onClick={onClose} outlined>
          閉じる
        </Button>
      </div>
    </form>
  );
};

export const NoroshiMasterEditForm = withFormik<Props, Partial<NoroshiMaster>>({
  validationSchema: Yup.object().shape({
    message: Yup.string().required('メッセージの入力は必須です'),
  }),
  mapPropsToValues: ({ noroshiMaster }) => {
    if (!noroshiMaster) {
      return {
        message: '',
      };
    }
    return noroshiMaster;
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },
  enableReinitialize: true,
})(Form);
