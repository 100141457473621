import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { noroshiMasterListActions } from '../noroshiMaster/noroshiMasterListSlice';
import { createNoroshiMasterBulk } from './noroshiMasterCreateAPI';

export interface NoroshiMasterCreateState {
  isOpenModal: boolean;
}

const initialState: NoroshiMasterCreateState = {
  isOpenModal: false,
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const createNoroshiMasterBulkAsync = createAsyncThunk<
  Promise<void>,
  string[],
  AsyncThunkConfig
>(
  'noroshiMasterCreate/create',
  async (messages: string[], { dispatch, getState }) => {
    const state = getState();
    if (state.auth.currentUserToken) {
      await createNoroshiMasterBulk(messages, state.auth.currentUserToken);
      // 狼煙マスタを再取得
      dispatch(noroshiMasterListActions.resetPage());
      dispatch(noroshiMasterListActions.fetchNoroshiMasters());
    } else {
      throw new Error('Authorization Error.');
    }
  },
);

export const noroshiMasterCreateSlice = createSlice({
  name: 'noroshiMasterCreate',
  initialState,
  reducers: {
    openModal: (state: NoroshiMasterCreateState, _: PayloadAction<void>) => {
      state.isOpenModal = true;
    },
    closeModal: (state: NoroshiMasterCreateState, _: PayloadAction<void>) => {
      state.isOpenModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createNoroshiMasterBulkAsync.fulfilled,
      (state: NoroshiMasterCreateState) => {
        state.isOpenModal = false;
      },
    );
  },
});

export const noroshiMasterCreateActions = {
  ...noroshiMasterCreateSlice.actions,
  createNoroshiMasterBulk: createNoroshiMasterBulkAsync,
};

export const selectIsOpenModal = (state: RootState) =>
  state.noroshiMasterCreate.isOpenModal;

export default noroshiMasterCreateSlice.reducer;
