import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import * as firebaseAuth from '../../services/firebase/auth';
import { RootState, AppThunk, AppDispatch } from '../../app/store';
import { AdminUser } from '../../models/dtos/AdminUser';
import { createUser, findUser } from './authService';

export type LoginPayload = {
  user: AdminUser;
  idToken: string;
};

export type AuthState = {
  currentUser?: AdminUser;
  currentUserToken?: string;
  isLoggedIn: boolean;
  isFirstLoaded: boolean;
};

const initialState: AuthState = {
  currentUser: undefined,
  currentUserToken: undefined,
  isLoggedIn: false,
  isFirstLoaded: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state: AuthState, action: PayloadAction<LoginPayload>) {
      state.currentUser = action.payload.user;
      state.currentUserToken = action.payload.idToken;
      state.isLoggedIn = true;
      state.isFirstLoaded = true;
    },
    logout(state: AuthState) {
      state.currentUser = undefined;
      state.currentUserToken = undefined;
      state.isLoggedIn = false;
      state.isFirstLoaded = true;
    },
  },
});

export const authActions = {
  ...authSlice.actions,
  checkAuth: (): AppThunk => {
    return async (dispatch: AppDispatch) => {
      firebase
        .auth()
        .onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
          console.log('onAuthStateChange:', firebaseUser);
          if (firebaseUser) {
            // 登録済みのユーザが見つからない場合は新規作成する
            let user = await findUser(firebaseUser.uid);
            if (!user) {
              user = await createUser(firebaseUser);
            }
            if (user.isAdmin) {
              const googleUser = gapi.auth2.getAuthInstance().currentUser.get();
              const authResponse = googleUser.getAuthResponse();
              dispatch(
                authActions.login({
                  user: user,
                  idToken: authResponse.id_token,
                }),
              );
            } else {
              window.alert('ログイン権限がありません');
              await firebaseAuth.logout();
              dispatch(authActions.logout());
            }
          } else {
            dispatch(authActions.logout());
          }
        });
    };
  },
};

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsFirstLoaded = (state: RootState) =>
  state.auth.isFirstLoaded;

export default authSlice.reducer;
