import { endpoint } from '../../../services/bff';
import { RecentPost } from '../../../models/dtos/RecentPost';
import { Pagination } from '../../../models/dtos/Pagination';

export const fetchRecentPosts = async (
  page: number,
  count: number,
  idToken: string,
): Promise<Pagination<RecentPost>> => {
  const res = await fetch(
    `${endpoint()}/v1/dashboard/recent-posts?page=${page}&count=${count}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return res.json();
};
