import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import { noroshiMasterListActions } from '../noroshiMaster/noroshiMasterListSlice';
import { updateNoroshiMaster } from './noroshiMasterEditAPI';

export interface NoroshiMasterEditState {
  initialValues?: Partial<NoroshiMaster>;
  isOpenModal: boolean;
}

const initialState: NoroshiMasterEditState = {
  initialValues: undefined,
  isOpenModal: false,
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const updateNoroshiMastersAsync = createAsyncThunk<
  Promise<void>,
  Partial<NoroshiMaster>,
  AsyncThunkConfig
>(
  'noroshiMasterEdit/update',
  async (noroshiMaster: Partial<NoroshiMaster>, { dispatch, getState }) => {
    const state = getState();
    if (state.auth.currentUserToken) {
      await updateNoroshiMaster(noroshiMaster, state.auth.currentUserToken);
      // 狼煙マスタを再取得
      dispatch(noroshiMasterListActions.resetPage());
      dispatch(noroshiMasterListActions.fetchNoroshiMasters());
    } else {
      throw new Error('Authorization Error.');
    }
  },
);

export const noroshiMasterEditSlice = createSlice({
  name: 'noroshiMasterEdit',
  initialState,
  reducers: {
    openModal: (
      state: NoroshiMasterEditState,
      action: PayloadAction<Partial<NoroshiMaster>>,
    ) => {
      if (action.payload && action.payload.id && action.payload.message) {
        state.initialValues = action.payload;
        state.isOpenModal = true;
      }
    },
    closeModal: (state: NoroshiMasterEditState, _: PayloadAction<void>) => {
      state.initialValues = undefined;
      state.isOpenModal = false;
    },
  },
  extraReducers: (builder) => {
    // 編集が成功した場合はモーダルを閉じる
    builder.addCase(
      updateNoroshiMastersAsync.fulfilled,
      (state: NoroshiMasterEditState) => {
        state.initialValues = undefined;
        state.isOpenModal = false;
      },
    );
  },
});

export const noroshiMasterEditActions = {
  ...noroshiMasterEditSlice.actions,
  updateNoroshiMaster: updateNoroshiMastersAsync,
};

export const selectInitialValues = (state: RootState) =>
  state.noroshiMasterEdit.initialValues;
export const selectIsOpenModal = (state: RootState) =>
  state.noroshiMasterEdit.isOpenModal;

export default noroshiMasterEditSlice.reducer;
