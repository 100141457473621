import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { NoroshiSuggestion } from '../../models/dtos/NoroshiSuggestion';
import { Pagination } from '../../models/dtos/Pagination';
import { fetchNoroshiSuggestions } from './noroshiSuggestionListAPI';

export interface NoroshiSuggestionListState {
  suggestions: NoroshiSuggestion[];
  totalCount: number;
  page: number;
  count: number;
  search: string;
  checks: number[];
}

const initialState: NoroshiSuggestionListState = {
  suggestions: [],
  totalCount: 0,
  page: 1,
  count: 100,
  search: '',
  checks: [],
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const fetchNoroshiSuggestionsAsync = createAsyncThunk<
  Pagination<NoroshiSuggestion>,
  undefined,
  AsyncThunkConfig
>('noroshiSuggestionList/fetch', async (_, { getState }) => {
  const state = getState();
  const { search, page, count } = state.noroshiSuggestionList;
  if (state.auth.currentUserToken) {
    return fetchNoroshiSuggestions(
      search,
      page,
      count,
      state.auth.currentUserToken,
    );
  } else {
    throw new Error('Authorization Error.');
  }
});

export const noroshiSuggestionListSlice = createSlice({
  name: 'noroshiSuggestionList',
  initialState,
  reducers: {
    changeSearch: (
      state: NoroshiSuggestionListState,
      action: PayloadAction<string>,
    ) => {
      state.search = action.payload;
    },
    changePage: (
      state: NoroshiSuggestionListState,
      action: PayloadAction<number>,
    ) => {
      state.page = action.payload;
    },
    resetPage: (state: NoroshiSuggestionListState) => {
      state.page = 1;
    },
    checkAll: (state: NoroshiSuggestionListState, _: PayloadAction<void>) => {
      if (
        state.suggestions.every((master) => state.checks.includes(master.id))
      ) {
        state.checks = [];
      } else {
        state.checks = state.suggestions.map((master) => master.id);
      }
    },
    check: (
      state: NoroshiSuggestionListState,
      action: PayloadAction<number>,
    ) => {
      if (state.checks.includes(action.payload)) {
        state.checks = state.checks.filter((id) => id !== action.payload);
      } else {
        state.checks.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNoroshiSuggestionsAsync.fulfilled,
      (
        state: NoroshiSuggestionListState,
        action: PayloadAction<Pagination<NoroshiSuggestion>>,
      ) => {
        state.suggestions = action.payload.results;
        state.totalCount = action.payload.totalCount;
      },
    );
  },
});

export const noroshiSuggestionListActions = {
  ...noroshiSuggestionListSlice.actions,
  fetchNoroshiSuggestions: fetchNoroshiSuggestionsAsync,
};

export const selectSuggestions = (state: RootState) =>
  state.noroshiSuggestionList.suggestions;
export const selectTotalCount = (state: RootState) =>
  state.noroshiSuggestionList.totalCount;
export const selectPage = (state: RootState) =>
  state.noroshiSuggestionList.page;
export const selectCount = (state: RootState) =>
  state.noroshiSuggestionList.count;
export const selectSearch = (state: RootState) =>
  state.noroshiSuggestionList.search;
export const selectChecks = (state: RootState) =>
  state.noroshiSuggestionList.checks;

export default noroshiSuggestionListSlice.reducer;
