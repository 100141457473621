import { NoroshiSuggestion } from '../../models/dtos/NoroshiSuggestion';
import { Pagination } from '../../models/dtos/Pagination';
import { endpoint } from '../../services/bff';

export const fetchNoroshiSuggestions = async (
  message: string,
  page: number,
  count: number,
  idToken: string,
): Promise<Pagination<NoroshiSuggestion>> => {
  const res = await fetch(
    `${endpoint()}/v1/noroshi-suggestion?message=${message}&page=${page}&count=${count}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return res.json();
};
