export class CreateNoroshiMasterBulk {
  messages!: string;

  messagesToArray() {
    if (!this.messages) {
      return [];
    }
    // 改行で分割して空白行を削除
    const messages = this.messages.split('\n').filter((message) => !!message);
    // 重複排除してから返す
    return Array.from(new Set(messages));
  }
}
