import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import { Pagination } from '../../models/dtos/Pagination';
import {
  deleteNoroshiMasters,
  fetchNoroshiMasters,
} from './noroshiMasterListAPI';

export interface NoroshiMasterListState {
  masters: NoroshiMaster[];
  totalCount: number;
  page: number;
  count: number;
  search: string;
  checks: number[];
}

const initialState: NoroshiMasterListState = {
  masters: [],
  totalCount: 0,
  page: 1,
  count: 100,
  search: '',
  checks: [],
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const fetchNoroshiMastersAsync = createAsyncThunk<
  Pagination<NoroshiMaster>,
  undefined,
  AsyncThunkConfig
>('noroshiMasterList/fetch', async (_, { getState }) => {
  const state = getState();
  const { search, page, count } = state.noroshiMasterList;
  if (state.auth.currentUserToken) {
    return fetchNoroshiMasters(
      search,
      page,
      count,
      state.auth.currentUserToken,
    );
  } else {
    throw new Error('Authorization Error.');
  }
});

const deleteNoroshiMastersAsync = createAsyncThunk<
  void,
  number[],
  AsyncThunkConfig
>('noroshiMasterList/deleteBulk', async (ids, { dispatch, getState }) => {
  const state = getState();
  if (state.auth.currentUserToken) {
    await deleteNoroshiMasters(ids, state.auth.currentUserToken);
    // 狼煙マスタを再取得
    dispatch(noroshiMasterListActions.resetPage());
    dispatch(noroshiMasterListActions.fetchNoroshiMasters());
  } else {
    throw new Error('Authorization Error.');
  }
});

export const noroshiMasterListSlice = createSlice({
  name: 'noroshiMasterList',
  initialState,
  reducers: {
    changeSearch: (
      state: NoroshiMasterListState,
      action: PayloadAction<string>,
    ) => {
      state.search = action.payload;
    },
    changePage: (
      state: NoroshiMasterListState,
      action: PayloadAction<number>,
    ) => {
      state.page = action.payload;
    },
    resetPage: (state: NoroshiMasterListState) => {
      state.page = 1;
    },
    checkAll: (state: NoroshiMasterListState, _: PayloadAction<void>) => {
      if (state.masters.every((master) => state.checks.includes(master.id))) {
        state.checks = [];
      } else {
        state.checks = state.masters.map((master) => master.id);
      }
    },
    check: (state: NoroshiMasterListState, action: PayloadAction<number>) => {
      if (state.checks.includes(action.payload)) {
        state.checks = state.checks.filter((id) => id !== action.payload);
      } else {
        state.checks.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNoroshiMastersAsync.fulfilled,
      (
        state: NoroshiMasterListState,
        action: PayloadAction<Pagination<NoroshiMaster>>,
      ) => {
        state.masters = action.payload.results;
        state.totalCount = action.payload.totalCount;
      },
    );
    builder.addCase(
      deleteNoroshiMastersAsync.fulfilled,
      (state: NoroshiMasterListState, action: PayloadAction<void>) => {
        state.checks = [];
      },
    );
  },
});

export const noroshiMasterListActions = {
  ...noroshiMasterListSlice.actions,
  fetchNoroshiMasters: fetchNoroshiMastersAsync,
  deleteNoroshiMasters: deleteNoroshiMastersAsync,
};

export const selectMasters = (state: RootState) =>
  state.noroshiMasterList.masters;
export const selectTotalCount = (state: RootState) =>
  state.noroshiMasterList.totalCount;
export const selectPage = (state: RootState) => state.noroshiMasterList.page;
export const selectCount = (state: RootState) => state.noroshiMasterList.count;
export const selectSearch = (state: RootState) =>
  state.noroshiMasterList.search;
export const selectChecks = (state: RootState) =>
  state.noroshiMasterList.checks;

export default noroshiMasterListSlice.reducer;
