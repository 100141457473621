import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecentPost } from '../../../models/dtos/RecentPost';
import { AppDispatch, RootState } from '../../../app/store';
import { fetchRecentPosts } from './recentPostsAPI';
import { Pagination } from '../../../models/dtos/Pagination';

export interface RecentPostsState {
  posts: RecentPost[];
  totalCount: number;
  page: number;
  count: number;
}

const initialState: RecentPostsState = {
  posts: [],
  totalCount: 0,
  page: 1,
  count: 20,
};

type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

const fetchRecentPostsAsync = createAsyncThunk<
  Pagination<RecentPost>,
  undefined,
  AsyncThunkConfig
>('recentPosts/fetch', async (_, { getState }) => {
  const state = getState();
  const { page, count } = state.recentPosts;
  if (state.auth.currentUserToken) {
    return fetchRecentPosts(page, count, state.auth.currentUserToken);
  } else {
    throw new Error('Authorization Error.');
  }
});

export const recentPostsSlice = createSlice({
  name: 'dashboard/recentPosts',
  initialState,
  reducers: {
    changePage: (state: RecentPostsState, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetPage: (state: RecentPostsState) => {
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRecentPostsAsync.fulfilled,
      (
        state: RecentPostsState,
        action: PayloadAction<Pagination<RecentPost>>,
      ) => {
        state.posts = action.payload.results;
        state.totalCount = action.payload.totalCount;
      },
    );
  },
});

export const recentPostsActions = {
  ...recentPostsSlice.actions,
  fetchRecentPosts: fetchRecentPostsAsync,
};

export const selectRecentPosts = (state: RootState) => state.recentPosts.posts;
export const selectTotalCount = (state: RootState) =>
  state.recentPosts.totalCount;
export const selectPage = (state: RootState) => state.recentPosts.page;
export const selectCount = (state: RootState) => state.recentPosts.count;

export default recentPostsSlice.reducer;
