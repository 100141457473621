import React, { useCallback } from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import {
  noroshiMasterEditActions,
  selectInitialValues,
  selectIsOpenModal,
} from './noroshiMasterEditSlice';
import { NoroshiMasterEditForm } from './NoroshiMasterEditForm';
import styles from './NoroshiMasterEdit.module.css';

Modal.setAppElement('#root');

export const NoroshiMasterEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const initialValues = useAppSelector(selectInitialValues);
  const isOpenModal = useAppSelector(selectIsOpenModal);

  const updateNoroshiMaster = useCallback(
    (noroshiMaster: Partial<NoroshiMaster>) => {
      dispatch(noroshiMasterEditActions.updateNoroshiMaster(noroshiMaster));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch(noroshiMasterEditActions.closeModal());
  }, [dispatch]);

  return (
    <Modal
      className={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={isOpenModal}
      onRequestClose={closeModal}
    >
      <NoroshiMasterEditForm
        noroshiMaster={initialValues}
        onSubmit={updateNoroshiMaster}
        onClose={closeModal}
      />
    </Modal>
  );
};
