import { endpoint } from '../../../services/bff';
import { User } from '../../../models/dtos/User';

export const fetchUser = async (
  userId: string,
  idToken: string,
): Promise<User> => {
  const res = await fetch(`${endpoint()}/v1/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
  return res.json();
};
