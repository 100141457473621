import { endpoint } from '../../../../services/bff';
import { RecentReaction } from '../../../../models/dtos/RecentReaction';
import { Pagination } from '../../../../models/dtos/Pagination';

export const fetchRecentReactions = async (
  userId: string,
  page: number,
  count: number,
  idToken: string,
): Promise<Pagination<RecentReaction>> => {
  const res = await fetch(
    `${endpoint()}/v1/users/${userId}/recent-reactions?page=${page}&count=${count}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return res.json();
};
