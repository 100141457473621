import React, { useState } from 'react';
import { FormikProps, withFormik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { plainToClass } from 'class-transformer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { CreateNoroshiMasterBulk } from '../../models/dtos/CreateNoroshiMasterBulk';
import { Button } from '../../components/button/Button';
import { Table } from '../../components/table/Table';
import { Td } from '../../components/table/Td';
import { Thead } from '../../components/table/Thead';
import { Th } from '../../components/table/Th';
import { Tbody } from '../../components/table/Tbody';
import styles from './NoroshiMasterCreateForm.module.css';

type Props = {
  onSubmit(messages: string[]): void;
  onClose(): void;
};

const placeholder = `作成したい狼煙を入力してください
複数の狼煙を作成する場合は改行区切りで続けて狼煙を入力してください`;

const Form: React.FC<Props & FormikProps<CreateNoroshiMasterBulk>> = ({
  values,
  dirty,
  isValid,
  handleSubmit,
  onClose,
}) => {
  const [isConfirm, setConfirm] = useState<boolean>(false);
  const messages = plainToClass(
    CreateNoroshiMasterBulk,
    values,
  ).messagesToArray();

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      {isConfirm ? (
        // 確認画面
        <div>
          <p className={styles.formHeader}>noroshiマスタ作成 (確認)</p>
          <div className={styles.formBody}>
            <div className="bg-white shadow rounded flex p-4 items-center">
              <span className="text-green-500 flex-shrink-0 ml-2 mr-6 text-2xl">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              <div>
                <p>{messages.length} 件の noroshi を作成します</p>
                <p>
                  修正する場合はフォーム下部の戻るボタンから前の画面に戻って編集してください
                </p>
              </div>
            </div>
            <div className="mt-4">
              <Table>
                <Thead>
                  <tr>
                    <Th>メッセージ</Th>
                  </tr>
                </Thead>
                <Tbody>
                  {messages.map((message) => (
                    <tr key={message}>
                      <Td>{message}</Td>
                    </tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
          <div className={styles.formFooter}>
            <Button type="button" onClick={handleSubmit}>
              作成
            </Button>
            <Button type="button" onClick={() => setConfirm(false)} outlined>
              戻る
            </Button>
            <Button type="button" onClick={onClose} outlined>
              閉じる
            </Button>
          </div>
        </div>
      ) : (
        // 作成フォーム
        <>
          <p className={styles.formHeader}>noroshiマスタ作成</p>
          <div className={styles.formBody}>
            <div className={styles.formGroup}>
              <div className={styles.inputContainer}>
                <Field
                  className={styles.input}
                  component="textarea"
                  name="messages"
                  rows={20}
                  placeholder={placeholder}
                />
                <div className={styles.inputError}>
                  <ErrorMessage name="messages" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.formFooter}>
            <Button
              type="button"
              disabled={!(dirty && isValid)}
              onClick={() => dirty && isValid && setConfirm(true)}
            >
              確認
            </Button>
            <Button type="button" onClick={onClose} outlined>
              閉じる
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export const NoroshiMasterCreateForm = withFormik<
  Props,
  CreateNoroshiMasterBulk
>({
  validationSchema: Yup.object().shape({
    messages: Yup.string().required('メッセージの入力は必須です'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const messages = plainToClass(
      CreateNoroshiMasterBulk,
      values,
    ).messagesToArray();
    props.onSubmit(messages);
    setSubmitting(false);
  },
  enableReinitialize: true,
})(Form);
