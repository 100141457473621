import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  noroshiMasterListActions,
  selectChecks,
  selectCount,
  selectMasters,
  selectPage,
  selectSearch,
  selectTotalCount,
} from './noroshiMasterListSlice';
import { noroshiMasterCreateActions } from '../noroshiMasterCreate/noroshiMasterCreateSlice';
import { NoroshiMasterSearchInput } from './NoroshiMasterSearchInput';
import { noroshiMasterEditActions } from '../noroshiMasterEdit/noroshiMasterEditSlice';
import { NoroshiMaster } from '../../models/dtos/NoroshiMaster';
import { NoroshiMasterCreate } from '../noroshiMasterCreate/NoroshiMasterCreate';
import { NoroshiMasterEdit } from '../noroshiMasterEdit/NoroshiMasterEdit';
import { Button } from '../../components/button/Button';
import { DropdownButton } from '../../components/button/DropdownButton';
import { Table } from '../../components/table/Table';
import { Thead } from '../../components/table/Thead';
import { Tbody } from '../../components/table/Tbody';
import { Th } from '../../components/table/Th';
import { Td } from '../../components/table/Td';
import { Pagination } from '../../components/navigation/Pagination';
import styles from './NoroshiMasterList.module.css';

export const NoroshiMasterList: React.FC = () => {
  const dispatch = useAppDispatch();
  const masters = useAppSelector(selectMasters);
  const totalCount = useAppSelector(selectTotalCount);
  const page = useAppSelector(selectPage);
  const count = useAppSelector(selectCount);
  const search = useAppSelector(selectSearch);
  const checks = useAppSelector(selectChecks);

  useEffect(() => {
    dispatch(noroshiMasterListActions.fetchNoroshiMasters());
  }, [dispatch, search, page]);

  const changeSearch = useCallback(
    (message: string) => {
      dispatch(noroshiMasterListActions.changeSearch(message));
    },
    [dispatch],
  );

  const changePage = useCallback(
    (page: number) => {
      dispatch(noroshiMasterListActions.changePage(page));
    },
    [dispatch],
  );

  const deleteMasterBulk = useCallback(() => {
    if (window.confirm('本当に削除してよろしいですか？')) {
      dispatch(noroshiMasterListActions.deleteNoroshiMasters(checks));
    }
  }, [dispatch, checks]);

  const checkAll = useCallback(() => {
    dispatch(noroshiMasterListActions.checkAll());
  }, [dispatch]);

  const check = useCallback(
    (id: number) => {
      return () => {
        dispatch(noroshiMasterListActions.check(id));
      };
    },
    [dispatch],
  );

  const openCreateModal = useCallback(() => {
    dispatch(noroshiMasterCreateActions.openModal());
  }, [dispatch]);

  const openEditModal = useCallback(
    (noroshiMaster: NoroshiMaster) => {
      dispatch(noroshiMasterEditActions.openModal(noroshiMaster));
    },
    [dispatch],
  );

  return (
    <div>
      <div className={styles.pageMenu}>
        <div className={classNames(styles.pageMenu, 'flex-1')}>
          <Button onClick={openCreateModal}>新規作成</Button>
          {checks.length > 0 && (
            <DropdownButton
              label="アクション"
              items={[
                {
                  className: 'text-red-500',
                  icon: faTrash,
                  label: '削除',
                  onClick: deleteMasterBulk,
                },
              ]}
            />
          )}
        </div>
        <div className={styles.pageMenu}>
          <NoroshiMasterSearchInput handleChange={changeSearch} />
          <Pagination
            totalCount={totalCount}
            page={page}
            count={count}
            onPageChange={changePage}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table>
          <Thead>
            <tr>
              <Th>
                <input
                  type="checkbox"
                  checked={
                    checks.length > 0 &&
                    masters.every((master) => checks.includes(master.id))
                  }
                  onChange={checkAll}
                />
              </Th>
              <Th>ID</Th>
              <Th>メッセージ</Th>
              <Th>投稿回数</Th>
              <Th>レギュラー狼煙数</Th>
              <Th>ベンチ狼煙数</Th>
              <Th>作成日時</Th>
              <Th>更新日時</Th>
              <Th></Th>
            </tr>
          </Thead>
          <Tbody>
            {masters.map((master) => (
              <tr key={master.id}>
                <Td>
                  <input
                    type="checkbox"
                    checked={checks.includes(master.id)}
                    onChange={check(master.id)}
                  />
                </Td>
                <Td>{master.id}</Td>
                <Td>{master.message}</Td>
                <Td className="text-right">{master.postCount}</Td>
                <Td className="text-right">{master.activeCount}</Td>
                <Td className="text-right">{master.inactiveCount}</Td>
                <Td>
                  {dayjs(master.createdAt).format('YYYY年MM月DD日 HH時mm分')}
                </Td>
                <Td>
                  {dayjs(master.updatedAt).format('YYYY年MM月DD日 HH時mm分')}
                </Td>
                <Td>
                  <button onClick={() => openEditModal(master)}>編集</button>
                </Td>
              </tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <NoroshiMasterCreate />
      <NoroshiMasterEdit />
    </div>
  );
};
