declare global {
  interface Window {
    gapi: any;
  }
}

const SCOPES = ['profile', 'email'];

// Initialize gapi client.
export const initializeGoogleApis = async () => {
  await new Promise((resolve) =>
    window.gapi.load('client:auth2', () => resolve(undefined)),
  );
  console.log('gapi: client:auth2 loaded', window.gapi.client);
  await window.gapi.client.init({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: SCOPES.join(' '),
  });
  console.log('gapi: client initialized');
};

export const gapi = window.gapi;
